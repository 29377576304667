import React, { useEffect, useState } from 'react'
import { useRoutes } from 'raviger'
import { MDBCol, MDBContainer, MDBRow } from 'mdbreact'

import './EPCTLPage.styl'
import { http } from '@tools'
import { PageSpinner } from '@nike/epic-react-ui'
import { CategoryTitleBar, SideNavigation, Markdown } from '@components'

import aboutPageURL from './pages/about.md'
import installingPageURL from './pages/installing.md'
import usingExamplesPageURL from './pages/using-the-examples.md'

import configurationPageURL from './pages/configuration.md'
import configurationFilePageURL from './pages/configuration-file.md'
import environmentVariablesPageURL from './pages/environment-variables.md'

import authenticationPageURL from './pages/authentication.md'
import commandStructurePageURL from './pages/command-structure.md'
import specifyingParameterValuesPageURL from './pages/specifying-parameter-values.md'
import cliSkeletonsPageURL from './pages/cli-skeletons.md'
import controllingOutputPageURL from './pages/controlling-output.md'
import returnCodesPageURL from './pages/return-codes.md'

export default function EPCTLPage () {
  const [aboutPage, isAboutPageLoading] = usePage(aboutPageURL)
  const [installingPage, isInstallingPageLoading] = usePage(installingPageURL)
  const [usingExamplesPage, isUsingExamplesPageLoading] = usePage(usingExamplesPageURL)
  const [configurationPage, isConfigurationPageLoading] = usePage(configurationPageURL)
  const [configurationFilePage, isConfigurationFilePageLoading] = usePage(configurationFilePageURL)
  const [environmentVariablesPage, isEnvironmentVariablesPageLoading] = usePage(environmentVariablesPageURL)
  const [authenticationPage, isAuthenticationPageLoading] = usePage(authenticationPageURL)
  const [commandStructurePage, isCommandStructurePageLoading] = usePage(commandStructurePageURL)
  const [specifyingParameterValuesPage, isSpecifyingParameterValuesPageLoading] = usePage(specifyingParameterValuesPageURL)
  const [cliSkeletonsPage, isCLISkeletonsPageLoading] = usePage(cliSkeletonsPageURL)
  const [controllingOutputPage, isControllingOutputPageLoading] = usePage(controllingOutputPageURL)
  const [returnCodesPage, isReturnCodesPageLoading] = usePage(returnCodesPageURL)
  const routes = {
    '/clis/epctl': () => <Markdown allowHtml source={aboutPage} />,
    '/clis/epctl/installing': () => <Markdown allowHtml source={installingPage} />,
    '/clis/epctl/using-the-examples': () => <Markdown allowHtml source={usingExamplesPage} />,

    // configuration
    '/clis/epctl/configuration': () => <Markdown allowHtml source={configurationPage} />,
    '/clis/epctl/configuration-file': () => <Markdown allowHtml source={configurationFilePage} />,
    '/clis/epctl/environment-variables': () => <Markdown allowHtml source={environmentVariablesPage} />,

    // usage
    '/clis/epctl/authentication': () => <Markdown allowHtml source={authenticationPage} />,
    '/clis/epctl/command-structure': () => <Markdown allowHtml source={commandStructurePage} />,
    '/clis/epctl/specifying-parameter-values': () => <Markdown allowHtml source={specifyingParameterValuesPage} />,
    '/clis/epctl/cli-skeletons-and-input': () => <Markdown allowHtml source={cliSkeletonsPage} />,
    '/clis/epctl/controlling-command-output': () => <Markdown allowHtml source={controllingOutputPage} />,
    '/clis/epctl/understanding-return-codes': () => <Markdown allowHtml source={returnCodesPage} />
  }
  const route = useRoutes(routes)
  const sidenav = [
    {
      title: 'Getting Started',
      links: [
        { text: 'About', path: '/clis/epctl' },
        { text: 'Installing', path: '/clis/epctl/installing' },
        { text: 'Using the Examples', path: '/clis/epctl/using-the-examples' }
      ]
    },
    {
      title: 'Configuration',
      links: [
        { text: 'Basics', path: '/clis/epctl/configuration' },
        { text: 'Configuration File', path: '/clis/epctl/configuration-file' },
        { text: 'Environment Variables', path: '/clis/epctl/environment-variables' }
      ]
    },
    {
      title: 'Usage',
      links: [
        { text: 'Authentication', path: '/clis/epctl/authentication' },
        { text: 'Command Structure', path: '/clis/epctl/command-structure' },
        { text: 'Specifying Parameter Values', path: '/clis/epctl/specifying-parameter-values' },
        { text: 'CLI Skeletons & Input', path: '/clis/epctl/cli-skeletons-and-input' },
        { text: 'Controlling Command Output', path: '/clis/epctl/controlling-command-output' },
        { text: 'Understanding Return Codes', path: '/clis/epctl/understanding-return-codes' }
      ]
    }
  ]

  if (
    isAboutPageLoading ||
    isInstallingPageLoading ||
    isUsingExamplesPageLoading ||
    isConfigurationPageLoading ||
    isConfigurationFilePageLoading ||
    isEnvironmentVariablesPageLoading ||
    isAuthenticationPageLoading ||
    isCommandStructurePageLoading ||
    isSpecifyingParameterValuesPageLoading ||
    isCLISkeletonsPageLoading ||
    isControllingOutputPageLoading ||
    isReturnCodesPageLoading
  ) return <PageSpinner show={false} />

  return (
    <div>
      <CategoryTitleBar color={'peach'} subtitle={'Command-Line Tools'} />
      <MDBContainer className={'my-5'}>
        <MDBRow>
          <SideNavigation sections={sidenav} />
          <MDBCol size={'8'} className={'offset-1'}>
            <div className={'markdown-content mb-5'}>{route}</div>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </div>
  )
}

function usePage (page) {
  const [text, setText] = useState(null)
  const [isLoading, setLoading] = useState(true)
  useEffect(() => {
    fetchPage(page).then(res => res.text()).then(
      content => {
        setText(content)
        setLoading(false)
      }
    )
  }, [page])
  return [text, isLoading]
}

async function fetchPage (page) {
  return await http.get(page)
}
