import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { useRoutes } from 'raviger'
import { Provider as TitleProvider } from 'react-doc-title'

import {
  initializeUserAuth,
  hasAuthCheckFinished,
  selectLoginError,
  selectAccessToken,
  selectEmail,
  loginUser,
  logoutUser
} from '@nike/oauth-client'
import { Button, PageSpinner, Feedback } from '@nike/epic-react-ui'
import IntegratedPlatform from '@nike/console-integrated-platform'

import config from '@config'
import routes from './routes'
import { LoginRequired } from '@auth'
import { NotFoundPage } from '@pages'
import ConsoleProvider from './ConsoleProvider'
import { Error, Navigation, Footer } from '@components'

const App = ({ initializeUserAuth, loginError, loginFinished, loginUser, email, token }) => {
  useEffect(() => { initializeUserAuth() }, [initializeUserAuth])

  const routeResult = useRoutes(routes) || <NotFoundPage />

  if (loginError) {
    return (
      <div>
        <Error error={loginError} />
        <Button onClick={loginUser}>Login</Button>
      </div>
    )
  }

  if (!loginFinished) {
    return <PageSpinner show={true} />
  }

  return (
    <ConsoleProvider>
      <TitleProvider title='Consume'>
        <IntegratedPlatform
          name={'Consume'}
          slackURL={config.slackChannel}
          isLoggedIn={!!token}
          accessToken={token}
          hasAuthCheckFinished={loginFinished}
          Feedback={Feedback}
          feedbackConfig={config.feedback}
          showConsoleNav={config.showConsoleNav}
        >
          <Navigation email={email} />
          <LoginRequired>
            {routeResult}
          </LoginRequired>
          <Footer />
        </IntegratedPlatform>
      </TitleProvider>
    </ConsoleProvider>
  )
}

export default connect(
  state => ({
    loginFinished: hasAuthCheckFinished(state),
    loginError: selectLoginError(state),
    email: selectEmail(state),
    token: selectAccessToken(state)
  }),
  { initializeUserAuth, loginUser, logoutUser }
)(App)
