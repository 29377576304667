import React from 'react'

import { MDBCard, MDBCardBody, MDBRow, MDBCol } from 'mdbreact'

import './CategoryTitleBar.styl'

const CategoryTitleBar = ({ color, subtitle }) => {
  return (
    <MDBCard className={`card-intro ${color}-gradient`}>
      <MDBCardBody className="white-text text-center">
        <MDBRow center className="d-flex">
          <MDBCol md={'6'}>
            <h3 className="font-weight-bold pt-2 epic-font-brand">{subtitle}</h3>
          </MDBCol>
        </MDBRow>
      </MDBCardBody>
    </MDBCard>
  )
}

export default CategoryTitleBar
