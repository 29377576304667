import { combineReducers } from 'redux'
import { reducer as auth } from '@nike/oauth-client'
import { parseErrorMiddleware } from '@nike/redux-action-utils'

const rootReducer = combineReducers({
  auth,
  parseErrorMiddleware
})

export default rootReducer
