import React from 'react'

import { Container } from '@nike/epic-react-ui'

import './ComingSoonPage.styl'

export default function ComingSoonPage () {
  return (
    <div className={'content-wrapper'}>
      <Container>
        <div className={'coming-soon mb-5'}>
          <p className={'text-center'}>** Coming Soon **</p>
        </div>
        <div className={'stay-tuned mt-5 pt-5'}>
          <p className={'text-center'}>We're almost there! Stay tuned.</p>
        </div>
      </Container>
    </div>
  )
}
