import React from 'react'

import { MDBFooter } from 'mdbreact'

const Footer = () => {
  return (
    <MDBFooter className="page-footer fixed-bottom epic-bg-default">
      <div className="text-center py-3">
        built by some barely-sentient lifeforms, for some kick-ass engineers
      </div>
    </MDBFooter>
  )
}

export default Footer
