import React from 'react'
import { ApolloClient, InMemoryCache, ApolloProvider } from '@apollo/client'

export default function ({ children }) {
  const client = new ApolloClient({
    cache: new InMemoryCache()
  })

  return <ApolloProvider client={client}>{children}</ApolloProvider>
}
