import React, { useEffect } from 'react'
import ReactMarkdown from 'react-markdown'

import CodeBlock from './CodeBlock'
import { slugify, scrollHashIntoView } from '../../util/index'

export default function Markdown ({ source, ...props }) {
  useEffect(scrollHashIntoView, [source])
  const renderers = {
    code: CodeBlock,
    heading: Heading
  }
  return (
    <ReactMarkdown {...props} source={source} renderers={renderers} escapeHtml={true} />
  )
}

function Heading (props) {
  const slug = slugifyHeading(props)
  return React.createElement(
    'h' + props.level,
    { id: slug },
    props.children
  )
}

function slugifyHeading (props) {
  const text = getChildText(props)
  // console.log('slug', props, text, props.innerText)
  return slugify(text)
}

function getChildText (props) {
  if (!props.children) return null
  if (props.children[0] && props.children[0].props) return getChildText(props.children[0].props)
  if (props.children === props.value) return props.value
  return null
}
