import React from 'react'

import {
  HomePage,
  EPCTLPage,
  ComingSoonPage
} from '@pages'

const routes = {
  '/': () => <HomePage />,

  // CLIs
  '/clis/epctl*': () => <EPCTLPage />,
  '/clis/nikectl*': () => <ComingSoonPage />,

  // SDKs
  '/sdks/cerberus*': () => <ComingSoonPage />,
  '/sdks/cloudred*': () => <ComingSoonPage />,
  '/sdks/dam*': () => <ComingSoonPage />,
  '/sdks/devportal*': () => <ComingSoonPage />,
  '/sdks/entitlements*': () => <ComingSoonPage />,
  '/sdks/map*': () => <ComingSoonPage />,
  '/sdks/mdcat*': () => <ComingSoonPage />,
  '/sdks/nsp*': () => <ComingSoonPage />,

  // Terraform
  '/terraform/registry*': () => <ComingSoonPage />,
  '/terraform/providers*': () => <ComingSoonPage />,

  // Tools
  '/tools/athens*': () => <ComingSoonPage />,
  '/tools/pipelines*': () => <ComingSoonPage />
}

export default routes
