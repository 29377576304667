import React from 'react'
import ReactDOM from 'react-dom'

import '@nike/epic-react-ui/dist/styles/main.css'
import '@fortawesome/fontawesome-free/css/all.min.css'
import 'bootstrap-css-only/css/bootstrap.min.css'
import 'mdbreact/dist/css/mdb.css'

import Root from '@root/Root'
import configureStore from '@root/store'
import * as serviceWorker from './serviceWorker'

export const store = configureStore()

ReactDOM.render(<Root store={store} />, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
