import React, { Component } from 'react'
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter'
import syntaxes from 'react-syntax-highlighter/dist/cjs/prism'

const { coy } = syntaxes

class CodeBlock extends Component {
  render () {
    const { language, value } = this.props
    return (
      <SyntaxHighlighter language={language} style={coy}>
        {value}
      </SyntaxHighlighter>
    )
  }
}

export default CodeBlock
