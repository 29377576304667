import React, { useEffect, useState } from 'react'

import './Hero.styl'
import { MDBIcon } from 'mdbreact'
import { Container, Button } from '@nike/epic-react-ui'

const Hero = () => {
  const [fun, setFun] = useState('//youtu.be/QfcLcDBII78')
  const videos = [
    '//youtu.be/QfcLcDBII78',
    '//youtu.be/dQw4w9WgXcQ',
    '//youtu.be/CN4IIgFz93k',
    '//youtu.be/WJT-4ZPQtNk',
    '//youtu.be/eyQaRC3AKGI',
    '//youtu.be/XoYu7K6Ywkg'
  ]

  useEffect(() => {
    const interval = setInterval(() => {
      const num = Math.floor(Math.random() * 5)
      setFun(videos[num])
    }, 3000)
    return () => {
      clearInterval(interval)
    }
  })

  return (
    <div className={'epic-bg-default home-banner'}>
      <Container style={{ textAlign: 'center' }}>
        <div className={'banner-content'}>
          <div className={'app-name'}>
            <span>Consume</span>
          </div>
          <div className="app-tagline">
            <p>
                The platform your
              <br />
                managers warned you about
            </p>
            { /* eslint-disable-next-line */ }
            <a href={fun} target={'_blank'} rel={'noopener noreferrer'}>
              <Button href={fun} inverse large>
                  Have Fun <MDBIcon icon="grin" />
              </Button>
            </a>
          </div>
        </div>
      </Container>
    </div>
  )
}

export default Hero
