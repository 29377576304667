import merge from 'deepmerge'

const base = {
  app: 'Consume',
  showConsoleNav: true,
  oAuth: {
    scope: 'profile openid email',
    response_type: 'authorization_code',
    redirect_uri: window.location.origin,
    client_id: 'nike.globaltech.consume-ui',
    token: 'https://api.aegis.nikecloud.com/v1/qa/token',
    authorization: 'https://api.aegis.nikecloud.com/v1/qa/authorize'
  },
  feedback: {
    channel: 'ep-consume-alerts'
  },
  slackChannel: 'slack://channel/?team=T0G3T5X2B&id=CJ2E3BA0M'
}

const test = merge(base, {
  oAuth: {
    token: 'https://api.aegis.nikecloud.com/v1/prod/token',
    authorization: 'https://api.aegis.nikecloud.com/v1/prod/authorize'
  }
})

const prod = merge(base, {
  oAuth: {
    token: 'https://api.aegis.nikecloud.com/v1/prod/token',
    authorization: 'https://api.aegis.nikecloud.com/v1/prod/authorize'
  }
})

const isTest = () => window.location.origin.includes('//test.consume.nike.com')

const isProd = () => window.location.origin.includes('//consume.nike.com')

let config
if (isProd()) {
  config = prod
} else if (isTest()) {
  config = test
} else {
  config = base
}

export default config
