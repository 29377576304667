export default function scrollIntoView (eleID) {
  const e = document.getElementById(eleID)
  if (!!e && e.scrollIntoView) {
    e.scrollIntoView(true)
  }
}

export function scrollTop (eleID) {
  const e = document.getElementById(eleID)
  if (e) {
    e.scrollTop = 0
  }
}

export function scrollHashIntoView () {
  if (document.location.hash) scrollIntoView(document.location.hash.substring(1))
}
