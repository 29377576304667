import React from 'react'

import { Hero } from '@components'
import splash from '@assets/ntip.png'
import { Container } from '@nike/epic-react-ui'
import { MDBRow, MDBCol, MDBIcon } from 'mdbreact'

function HomePage () {
  return (
    <div className={'content-wrapper'}>
      <Hero />
      <Container>
        <section className={'my-5'}>
          <h3 className="text-center mb-2">About Us</h3>
          <h5 className="card-subtitle text-black-50 mb-5 text-center text-lowercase">
            Get to know us ¯\_(ツ)_/¯ buy us a beer
          </h5>
          <MDBRow className="fadeIn">
            <MDBCol lg="6" md="12" className="px-4">
              <MDBRow className="mt-4 mb-5">
                <MDBCol size="1" className="mr-3">
                  <MDBIcon icon="plug" className="fa-2x purple-text" />
                </MDBCol>
                <MDBCol className="col-10">
                  <h5 className="feature-title">Plug 'n Play</h5>
                  <p className="grey-text">
                    A standardized and seamless experience across the toolset
                    allows you to easily connect platforms together
                  </p>
                </MDBCol>
              </MDBRow>
              <MDBRow className="mb-5">
                <MDBCol size="1" className="mr-3">
                  <MDBIcon icon="book" className="fa-2x blue-text" />
                </MDBCol>
                <MDBCol className="col-10">
                  <h5 className="feature-title">Detailed Documentation</h5>
                  <p className="grey-text">
                    We give you detailed user-friendly documentation at your
                    disposal. It will help you to implement your ideas without
                    sitting in "ticket hell".
                  </p>
                </MDBCol>
              </MDBRow>
              <MDBRow>
                <MDBCol size="1" className=" mr-3">
                  <MDBIcon icon="lightbulb" className="fa-2x green-text" />
                </MDBCol>
                <MDBCol size="10">
                  <h5 className="feature-title">A Guiding Light</h5>
                  <p className="grey-text">
                    We care about the development of our users. We have prepared
                    numerous guides, which allow you to learn how to use the
                    tools, as well as contribute back to them.
                  </p>
                </MDBCol>
              </MDBRow>
            </MDBCol>
            <MDBCol lg="6" md="12" className="mb-5 text-center">
              <img
                src={splash}
                alt="about"
                className={'rounded img-fluid z-depth-1-half'}
              />
            </MDBCol>
          </MDBRow>
        </section>
      </Container>
    </div>
  )
}

export default HomePage
