import { AuthEvents, selectAccessToken } from '@nike/oauth-client'
import { navigate } from 'raviger'

import { setGlobalHeader } from '@tools'

const events = new AuthEvents()

events.on('loginSuccess', (dispatch, getState) => {
  const state = getState().auth

  if (state.previousLocation) {
    let url = state.previousLocation.pathname
    if (state.previousLocation.query) url += `?${state.previousLocation.query}`
    if (state.previousLocation.hash) url += state.previousLocation.hash
    navigate(url, true)
  }

  setGlobalHeader('Authorization', 'Bearer ' + selectAccessToken(getState()))
})

events.on('loginError', (dispatch, getState) => {})

events.on('refreshSuccess', (dispatch, getState) => {
  setGlobalHeader('Authorization', 'Bearer ' + selectAccessToken(getState()))
})

events.on('refreshError', (dispatch, getState) => {})

events.on('logoutError', () => {
  navigate('/')
})

export default events
