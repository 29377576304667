import React from 'react'
import { Link } from 'raviger'

import config from '@config'
import logo from '@assets/logo.png'
import { NavBar } from '@nike/epic-react-ui'

export default function Navigation () {
  return (
    <NavBar
      logo={logo}
      name='Consume'
      rootPath='/'
      routes={[
        {
          text: 'CLIs',
          dropdownLinks: [
            { text: 'epctl', path: '/clis/epctl' },
            { text: 'nikectl', path: '/clis/nikectl' }
          ]
        },
        {
          text: 'SDKs',
          dropdownLinks: [
            { text: 'Cerberus', path: '/sdks/cerberus' },
            { text: 'CloudRed', path: '/sdks/cloudred' },
            { text: 'DAM', path: '/sdks/dam' },
            { text: 'DevPortal', path: '/sdks/devportal' },
            { text: 'Entitlements', path: '/sdks/entitlements' },
            { text: 'MAP', path: '/sdks/map' },
            { text: 'MDCat', path: '/sdks/mdcat' },
            { text: 'NSP (GEM)', path: '/sdks/nsp' }
          ]
        },
        {
          text: 'Terraform',
          dropdownLinks: [
            { text: 'Registry', path: '/terraform/registry' },
            { text: 'Providers', path: '/terraform/providers' }
          ]
        },
        {
          text: 'Tools',
          dropdownLinks: [
            { text: 'Athens', path: '/tools/athens' },
            { text: 'EP Pipelines', path: '/tools/pipelines' }
          ]
        }
      ]}
      RouterLink={Link}
      slackURL={config.slackChannel}
    />
  )
}
