import React from 'react'

import { Container } from '@nike/epic-react-ui'

import './NotFoundPage.styl'

export default function NotFoundPage () {
  return (
    <div className={'content-wrapper'}>
      <Container>
        <div className={'four-o-four mb-5'}>
          <p className={'text-center'}>404</p>
        </div>
        <div className={'not-found mt-5 pt-5'}>
          <p className={'text-center'}>Whoops! The page you are looking for could not be found.</p>
        </div>
      </Container>
    </div>
  )
}
