import React from 'react'

import { MDBBox, MDBListGroup, MDBListGroupItem, MDBCol } from 'mdbreact'

import './SideNavigation.styl'

const SideNavigation = ({ sections }) => {
  return (
    <MDBCol size={'3'} className={'mb-5'}>
      {sections.map(section => (
        <MDBBox className={'mb-5'} key={section.title}>
          <h5 className={'mb-3'}>{section.title}</h5>
          <MDBListGroup>
            {section.links.map(link => (
              <MDBListGroupItem href={link.path} key={link.text}>
                {link.text}
              </MDBListGroupItem>
            ))}
          </MDBListGroup>
        </MDBBox>
      ))}
    </MDBCol>
  )
}

export default SideNavigation
